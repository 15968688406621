export const getLastNews = () => {
	const url = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fpandanoticias.xyz%2Ffeed'

	return new Promise ((resolve, reject) => {
		fetch(url)
			.then(response => response.json())
			.then(data => {
				resolve(data.items)
			})
			.catch(error => {
				reject(error)
			})
	})
}