import React from 'react'
import { HomePage } from '../components/home/Index'
import { graphql } from 'gatsby'

const IndexPage = ({ data }) => {
	const filteredData = data.allFile.edges[0].node.internal.content
	const texts = JSON.parse(filteredData)
	
	return (
		<HomePage texts={texts} />
	)
}

export default IndexPage

export const query = graphql`
{
	allFile(filter: {name: {eq: "home(es)"}}) {
		edges {
			node {
				internal {
					content
				}
			}
		}
	}
}
`