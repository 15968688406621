import React, { useState, useEffect } from 'react'
import { useResizeListener } from '../../../hooks/useResizeListener'

import arrowImg from '../../../images/home/news/arrow_right.png'

import * as styles from './index.module.css'

export const NewsCarrousel = ({ items }) => {
	const newsWidth = 19
	const maxNumberOfNews = 3
	const [numberOfNews, setNumberOfNews] = useState(calculateInicialnews())
	const [carrouselMove, setCarrouselMove] = useState(0) //left

	const breakPoint1 =  useResizeListener(1040)
	const breakPoint2 =  useResizeListener(740)

	function calculateInicialnews ()  {
		let number = maxNumberOfNews
		if (breakPoint1) {
			number--
		}
		if (breakPoint2) {
			number--
		}
		return number
	} 

	useEffect(() => {
		if (breakPoint1) {
			setNumberOfNews(oldValue => {
				return oldValue - 1
			})
		} else {
			if (maxNumberOfNews > numberOfNews) {
				setNumberOfNews(oldValue => {
					return oldValue + 1
				})	
			}
		}
	}, [breakPoint1])

	useEffect(() => {
		if (breakPoint2) {
			setNumberOfNews(oldValue => {
				return oldValue - 1
			})
		} else {
			if (maxNumberOfNews > numberOfNews) {
				setNumberOfNews(oldValue => {
					return oldValue + 1
				})	
			}
		}
	}, [breakPoint2])

	const numOfMovements = () => {
		return (carrouselMove / newsWidth) + numberOfNews
	}

	const handleForwardClick = () => {
		if (numOfMovements() + numberOfNews <= items.length) {
			setCarrouselMove(carrouselMove + newsWidth * numberOfNews)
		} else {
			const advance = (numOfMovements() - items.length) * -1
			setCarrouselMove(carrouselMove + newsWidth * advance)
		}
	}

	const handleBackwardClick = () => {
		const newMove = carrouselMove - newsWidth * numberOfNews
		if (newMove >= 0) {
			setCarrouselMove(newMove)	
		} else {
			setCarrouselMove(0)
		}
	}

	return (
		<div className={styles.mainContainer} >
			<button onClick={handleForwardClick} className={styles.backwardButton}>
				<img src={arrowImg} alt='Flecha hacia la izquierda' />
			</button>
			<section className={styles.section} style={{'width': `${numberOfNews*newsWidth}em` }}>
				<div className={styles.carrousel} style={{'left': `-${carrouselMove}em`}}>
					{
						items && items.map((item, index) => {
							return (
								<a
									className={styles.carrousel_item}
									target='_blank'
									rel='noreferrer'
									href={item.link}
									key={`newsItem-${index}`}
								>
									<div className={styles.carrousel_imgContainer}>
										<img src={item.enclosure.link} alt="" />
									</div>
									<div className={styles.carrousel_titleContainer}>
										<h2 className={styles.carrousel_title}>
											{
												item.title.length > 64 
													?	`${item.title.slice(0, 64)}...`
													: item.title
											}
										</h2>
									</div>
								</a>
							)
						})
					}
				</div>
			</section>
			<button onClick={handleBackwardClick} className={styles.forwardButton}>
				<img src={arrowImg} alt='Flecha hacia la derecha' />
			</button>
		</div>
	)
}