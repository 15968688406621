import React from 'react'


export const BoldText = ({ text }) => { 
	if (!Array.isArray(text)) {
		return <p>{text}</p>
	}

	return (
		<p>
			{
				text.map((item, index) => {
					if (typeof item === 'string') {
						return item
					}
					return <b key={`boldText-${index}`}>{item.bold}</b>
				})
			}
		</p>
	)
}