import React from 'react'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import * as styles from './index.module.css' 

import mainImg from '../../../images/home/followUs/mainImg.png'

export const FollowUs = ({ texts }) => {
	const [isIntersecting, ref] = useIntersectionObserver()
	
	return (
		<section
			className={`${styles.section} ${isIntersecting ? styles.sectionbackground : ''}`}
			ref={ref}
		>
			<h1 className={styles.title}>{texts && texts.title}</h1>
			{
				isIntersecting && <img 
					src={mainImg}
					alt='empresas de panda group'
					className={styles.mainImg}
				/>
			}
		</section>
	)
}