import React from 'react'
import { getHeaderTexts } from '../../../utils/getHeaderTexts'
import { useScrollListener } from '../../../hooks/useScrollListener'
import { useResizeListener } from '../../../hooks/useResizeListener'
import { HamburgerMenu } from '../HamburgerMenu'
import logoImg from '../../../images/home/hero/logo_pandagroup.png'
import * as styles from './index.module.css'

export const Header = () => {
	const headerOnTop = !useScrollListener(50)
	const renderHamburgerMenu = useResizeListener(800)
	const texts = getHeaderTexts()

	return (
		<header className={`${styles.header} ${headerOnTop ? styles.header_solid : ''}`} >
			{
				headerOnTop && (
					<a href='#home'>
						<img className={styles.logo} src={logoImg} alt='panda group logo' />
					</a>
				)
			}
			{
				renderHamburgerMenu 
					? <HamburgerMenu texts={texts} />
					: (
						<nav className={styles.nav} >
							{
								texts && texts.links.map((item, index) => {
									return (
										<a href={item.url} key={`header-link-${index}`} className={styles.nav_item}>
											{item.name}
										</a>		
									)
								})			
							}
						</nav>
					)
			}
		</header>
	)
}