import React from 'react'
import { BoldText } from '../../general/BoldText'
import icon from '../../../images/home/introduction/unequal_icon.png'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import * as styles from './index.module.css'

export const Introduction = ({ texts }) => {
	const [isIntersecting, ref] = useIntersectionObserver()
	
	return (
		<section
			className={`${styles.section} ${isIntersecting ? styles.sectionBackground : ''}`}
			ref={ref}
		>
			<div className={styles.titleContainer}>
				<h1 className={styles.title} >
					{texts && texts.title[0]} <span>{texts && texts.title[1]}</span>
				</h1>
				<img src={icon} alt="icono de desigual" />
			</div>
			<div className={styles.mainContainer} >
				<div className={styles.textsContainer} >
					<BoldText text={texts.introTexts[0]}/>
				</div>
				<div className={styles.textsContainer} >
					<svg viewBox="0 0 38 210" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M2 0V88.5L34 107L2 127.5V210" stroke="white" strokeWidth="4"/>
					</svg>

					<BoldText text={texts.introTexts[1]}/>
				</div>
			</div>
		</section>
	)
}