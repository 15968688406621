import { useState, useEffect, useCallback } from 'react'

export const useScrollListener = (limit) => {
	const [onLimit, setonLimit] = useState(true)
	
	const handleScroll = useCallback(() => {
		if (window.scrollY > limit) {
			setonLimit(false)
		} 
		else {
			setonLimit(true)
		}
	}, [limit])

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [handleScroll])

	return onLimit
}