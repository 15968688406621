// extracted by mini-css-extract-plugin
export var section = "index-module--section--2-pYK";
export var sectionBackground = "index-module--sectionBackground--3SzWP";
export var mainTitle = "index-module--mainTitle--3Cu6F";
export var itemsContainer = "index-module--itemsContainer--2NbN6";
export var meetItem_article = "index-module--meetItem_article--3Iur1";
export var meetItem_banner_logo = "index-module--meetItem_banner_logo--pcJFM";
export var meetItem_title = "index-module--meetItem_title--lInRr";
export var meetItem_text = "index-module--meetItem_text--2v5BG";
export var meetItem_article_right = "index-module--meetItem_article_right--2F-c8";
export var meetItem_banner = "index-module--meetItem_banner--1MLz_";
export var meetItem_banner_img = "index-module--meetItem_banner_img--17OkN";
export var meetItem_banner_logo_right = "index-module--meetItem_banner_logo_right--5a7-C";
export var meetItem_title_right = "index-module--meetItem_title_right--38OJ_";
export var meetItem_text_right = "index-module--meetItem_text_right--30nLr";