import React, { useState, useEffect } from 'react'

import * as styles from './index.module.css'

import hamburgerIcon from '../../../images/header/hambuerger_icon.svg'
import hamburgerIcon_close from '../../../images/header/hamburger_close.svg'

export const HamburgerMenu = ({ texts }) => {
	const [showMenu, setShowMenu] = useState(false)
	const [animation, setAnimation] = useState(false)

	useEffect(() => {
		if (showMenu) {
			setTimeout(() => {
				setAnimation(true)
			}, 0)
		} else {
			setAnimation(false)
		}
	}, [showMenu])

	const handleClick = () => {
		if (showMenu) {
			setAnimation(false)
			setTimeout(() => {
				setShowMenu(false)
			}, 500)
		} else {
			setShowMenu(true)
		}
	}

	const handleCloseMenu = () => {
		setAnimation(false)
		setTimeout(() => {
			setShowMenu(false)
		}, 500)
	}

	return (
		<div className={styles.mainContainer}>
			<div className={`${styles.buttonContainer} ${showMenu ? styles.buttonContainer_solid : ''}`}>
				<button onClick={handleClick} className={styles.button}>
					<img src={!showMenu ? hamburgerIcon : hamburgerIcon_close} alt='icono de hamburgesa' />
				</button>
			</div>
			{
				showMenu && (
					<nav className={styles.nav} style={{top: animation ? "5em": '-200%'}}>
						{
							texts && texts.links.map((item, index) => {
								return (
									<a
										onClick={handleCloseMenu}
										className={styles.item}
										href={item.url}
										key={`header-link-${index}`}
									>
										{item.name}
									</a>		
								)
							})			
						}
					</nav>
				)
			}
		</div>
	)
}