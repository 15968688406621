import React from 'react'

import * as styles from './index.module.css'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import logoImg from '../../../images/home/hero/logo_pandagroup.png'

export const Hero = ({ texts }) => {
	const [isIntersecting, ref] = useIntersectionObserver()
	
	return (
		<section
			className={`${styles.section} ${isIntersecting ? styles.sectionBackground : ''}`} id='home'
			ref={ref}
		>
			<div className={styles.infoContainer}>
				<img src={logoImg} alt={texts.imgAlt} className={styles.logoImg}/>
				<h1 className={styles.mainTitle}>
					{texts && texts.mainTitle}
				</h1>
				<h2 className={styles.secondariTitle}>
					<span>{texts && texts.secondariTitle[0]}</span>
					{texts && texts.secondariTitle[1]}
				</h2>
				<h2 className={styles.secondariTitle__rignt}>
					{texts && texts.secondariTitle[2]}
				</h2>
				<div className={styles.buttonContainer}>
					<a href={texts && texts.button[0].url} className={styles.button}>
						{texts && texts.button[0].text}
					</a>
					<a href={texts && texts.button[1].url} className={styles.button}>
						{texts && texts.button[1].text}
					</a>
				</div>
			</div>
		</section>
	)
}