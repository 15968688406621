import React from 'react'
import { Layout } from '../../general/Layout'
import { Hero } from '../Hero'
import { Introduction } from '../Introduction'
import { Meet } from '../Meet'
import { TimeLine } from '../TimeLine'
import { AboutUs } from '../AboutUs'
import { Team } from '../Team'
import { FollowUs } from '../FollowUs'
import { PandaInWeb } from '../PandaInWeb'
import { News } from '../News'
import { Contact } from '../Contact'

export const HomePage = ({ texts }) => {
	return (
		<Layout>
			<Hero texts={texts.hero} />
			<Introduction texts={texts.introduction}/>
			<Meet texts={texts.meet}/>
			<AboutUs texts={texts.aboutUs}/>
			<TimeLine texts={texts.timeLine}/>
			<Team texts={texts.team}/>
			<FollowUs texts={texts.followUs}/>
			<PandaInWeb texts={texts.pandaInWeb}/>
			<News texts={texts.news}/>
			<Contact texts={texts.contact} />
		</Layout>
	)
}
