// extracted by mini-css-extract-plugin
export var section = "index-module--section--2wOx8";
export var sectionBackground = "index-module--sectionBackground--bh2v2";
export var title = "index-module--title--1f9yY";
export var presentation = "index-module--presentation--3qdJd";
export var finalText = "index-module--finalText--kOlpg";
export var teamCardsContainer = "index-module--teamCardsContainer--yy-4A";
export var cardContainer = "index-module--cardContainer--Ar7bU";
export var cardImg = "index-module--cardImg--2BJQi";
export var cardLink = "index-module--cardLink--20GyS";
export var cardName = "index-module--cardName--3yNPa";
export var cardPositin = "index-module--cardPositin--2Mtih";