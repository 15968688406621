import React from 'react'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import * as styles from './index.module.css'

const DateCard = ({ date, info }) => {
	return (
		<div className={styles.date}>
			<h2 className={styles.dateTile}>{date}</h2>
			<div className={styles.datePoint} ></div>
			<article className={styles.dateCard}>
				{
					info && info.map((item, index) => {
						if (item.type === 'year') {
							return (
								<h2
									key={`dateCard-${date}-year-${item.title}`}
									className={`${styles.dateCardYear} ${item.alignRight ? styles.dateCardsRight  : ''}`}
								>
									{item.title}
								</h2>
							)
						}

						return (
							<div
								key={`dateCard-${date}-item-${index}`}
								className={`${styles.dateCardInfoContainer} ${item.alignRight ? styles. dateCardsRight : ''}`}
							>
								{
									item.title && (
										<h2 className={styles.dateCardTitle} >{item.title}</h2>
									)
								}
								{
									item.description && (
										<p className={styles.dateCardDesciption} >-{item.description}</p>
									)
								}
							</div>
						)

					})
				}
			</article>
		</div>
	)
}

export const TimeLine = ({ texts }) => {
	const [isIntersecting, ref] = useIntersectionObserver()
	return (
		<section
			className={`${styles.section} ${isIntersecting ? styles.sectionBackground : ''}`}
			ref={ref}
		>
			<h1 className={styles.title}>
				{texts && texts.title}
			</h1>
			<p className={styles.description}>
				{texts && texts.description}
			</p>
			<div className={styles.timeLine}>
				{
					texts.cardsInfo && texts.cardsInfo.map((item, index) => {
						return (
							<DateCard
								key={`timeLine-Card-${index}`}
								date={item.year}
								info={item.info}
							/>
						)
					})
				}
			</div>
		</section>
	)

}