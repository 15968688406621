import React from 'react'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import * as styles from './index.module.css'

import arley from '../../../images/home/team/arley_lego.png'
import leydi from '../../../images/home/team/leydi_lego.png'
import typson from '../../../images/home/team/typson_lego.png'
import anna from '../../../images/home/team/anna_lego.png'

const teamImages = {
	'Arley Lozano': arley,
	"Leydi Angarita": leydi,
	"Typson Sánchez": typson,
	"Anna Lezama": anna
}

const TeamCard = ({ img = null, name, position, linkedIn }) => {
	return (
		<article className={styles.cardContainer}>
			<a
				href={linkedIn}
				target='_blank'
				rel='noreferrer'
				className={styles.cardLink}
			>
				<img src={img} alt='' className={styles.cardImg} />
				<h3 className={styles.cardName} >
					{name}
				</h3>
			</a>
			<h3 className={styles.cardPositin}>
				{position}
			</h3>
		</article>
	)
}

export const Team = ({ texts }) => {
	const [isIntersecting, ref] = useIntersectionObserver()
	return (
		<section
			className={`${styles.section} ${isIntersecting ? styles.sectionBackground : ''}`}
			id='team'
			ref={ref}
		>
			<h1 className={styles.title} >
				{texts && texts.title}
			</h1>
			<p className={styles.presentation} >
				{texts && texts.presepresentation}
			</p>
			<div className={styles.teamCardsContainer}>
				{
					(texts && isIntersecting) && texts.teamInfo.map((item, index) => {
						return (
							<TeamCard
								img={teamImages[item.name]}
								key={`teamCard-${index}`}
								name={item.name}
								position={item.position}
								linkedIn={item.linkedIn}
							/>
						)
					})
				}
			</div>
			<p className={styles.finalText}>
				{texts && texts.finalText}
			</p>
		</section>
	)
}