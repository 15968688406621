// extracted by mini-css-extract-plugin
export var section = "index-module--section--2oIsM";
export var sectionBackground = "index-module--sectionBackground--1yyBc";
export var title = "index-module--title--3wSxw";
export var infoSection = "index-module--infoSection--1Tx8h";
export var infoSection_description = "index-module--infoSection_description--2KTOn";
export var infoSection_emailContainer = "index-module--infoSection_emailContainer--2L7-u";
export var infoSection_emailText = "index-module--infoSection_emailText--1KGE0";
export var infoSection_email = "index-module--infoSection_email--2ZRbc";
export var infoSection_socialMedia = "index-module--infoSection_socialMedia--3xjA6";
export var infoSection_socialMedia_nav = "index-module--infoSection_socialMedia_nav--2fg_Y";
export var infoSection_socialMedia_item = "index-module--infoSection_socialMedia_item--38x5w";