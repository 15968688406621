import { useState, useEffect, useCallback } from 'react'

export const useResizeListener = (minWidth) => {
	const isRunningInBrowser = typeof window !== 'undefined'

	const [smallerWidth, setSmallerWidth] = useState(
		isRunningInBrowser
			? window.innerWidth < minWidth
			: false
	)

	const handleResize = useCallback(() => {
		if (window.innerWidth < minWidth) {
			setSmallerWidth(true)
		}
		else {
			setSmallerWidth(false)
		}
	}, [minWidth]) 
	
	useEffect(() => {
		if (isRunningInBrowser) {
			window.addEventListener("resize", handleResize)
		}
		
		return () => {
			if (isRunningInBrowser) {
				window.removeEventListener("resize", handleResize)
			}
		}
	}, [handleResize, isRunningInBrowser])
		
	return smallerWidth
}