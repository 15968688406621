import React from 'react'
import { BoldText } from '../../general/BoldText'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import * as styles from './index.module.css'

export const AboutUs = ({ texts }) => {
	const [isIntersecting, ref] = useIntersectionObserver()
	return (
		<section
			ref={ref}
			className={`${styles.section} ${isIntersecting ? styles.sectionBackground : ''}`}
			id='about_us'
		>
			<h1 className={styles.title} >
				{texts && texts.title}
			</h1>
			{
				texts && texts.paragraphs.map((item, index) => {
					return (
						<div
							className={styles.paragraphContainer} 
							key={`aboutUsParagraph=${index}`}
						>
							<BoldText text={item}/>
						</div>
					)
				})
			}
		</section>
	)
}