// extracted by mini-css-extract-plugin
export var section = "index-module--section--2Hk_E";
export var sectionBackground = "index-module--sectionBackground---PHUI";
export var title = "index-module--title--1x0lR";
export var description = "index-module--description--2w0nN";
export var timeLine = "index-module--timeLine--1CqTH";
export var date = "index-module--date--vedMJ";
export var dateTile = "index-module--dateTile--3Bt3y";
export var datePoint = "index-module--datePoint--3iJzo";
export var dateCard = "index-module--dateCard--3UbKK";
export var dateCardInfoContainer = "index-module--dateCardInfoContainer--3-7Q0";
export var dateCardsRight = "index-module--dateCardsRight--8szIO";
export var dateCardTitle = "index-module--dateCardTitle--16yo8";
export var dateCardDesciption = "index-module--dateCardDesciption--2yLTt";
export var dateCardYear = "index-module--dateCardYear--4sp3a";