import React from 'react'

import * as styles from './index.module.css'

const Item = ({ title, img, link }) => {
	return (
		<a
			className={styles.item}
			href={link}
			target='_blank'
			rel='noreferrer'
		>
			<img src={img} alt='' className={styles.item_img} />
			<h4 className={styles.item_title}>
				{title}
			</h4>
		</a>
	)
}

export const PandaInWebList = ({ list }) => {

	return (
		<div className={styles.listContainer}>
			{
				list.map((item) => {
					return (
						<Item
							key={`pandaInWebList-${item.title}`}
							title={item.title}
							img={item.img}
							link={item.link}
						/>
					)
				})
			}
		</div>
	)
}