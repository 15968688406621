import React, { Fragment, useState, useEffect } from 'react'
import { BoldText } from '../../general/BoldText'
import { PandaInWebList } from '../PandaInWebList'
import { useResizeListener } from '../../../hooks/useResizeListener'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'

import arrowImg from '../../../images/home/pandaInTheWeb/arrow.png'

import * as styles from './index.module.css'

export const PandaInWeb = ({ texts }) => {
	const [isIntersecting, ref] = useIntersectionObserver()
	const numberOfLinks = 3
	const listOfLinks = texts.list
	const [numOflist, setNumOfList] = useState(2) 
	const [forwardNum, setForwardNum] = useState(1)
	const [linksToShow, setLinksToShow] = useState(listOfLinks.slice(0, numberOfLinks * numOflist))

	const breakpoint = useResizeListener(1120)

	useEffect(() => {
		const newNumber = breakpoint ? 1 : 2
		setNumOfList(newNumber)
		setLinksToShow(listOfLinks.slice(0, numberOfLinks * newNumber))
	}, [breakpoint])

	const handleClick = () => {
		if (listOfLinks.length >= (linksToShow.length * forwardNum + numberOfLinks * numOflist)) {
			setLinksToShow(
				listOfLinks.slice(
					linksToShow.length * forwardNum,
					linksToShow.length * forwardNum + numberOfLinks * numOflist
				)
			)
			setForwardNum(forwardNum+1)
		} else {
			setLinksToShow(listOfLinks.slice(0, numberOfLinks * numOflist))
			setForwardNum(1)
		}
	}

	return (
		<section
			className={`${styles.section} ${isIntersecting ? styles.sectionBackground : ''}`}
			ref={ref}
		>
			<h1 className={styles.title}>
				{texts && texts.title}
			</h1>
			<BoldText text={texts.description}/>
			{ isIntersecting && (
				<div className={styles.listContainer}>
					{
						!breakpoint
							? (
								<Fragment>
									<PandaInWebList list={linksToShow.slice(0, numberOfLinks)} />
									<PandaInWebList list={linksToShow.slice(numberOfLinks)} />
								</Fragment>
							)
							: <PandaInWebList list={linksToShow} />
					}
				</div>
			)
			}
			<button onClick={handleClick} className={styles.button}>
				<img src={arrowImg} alt="Flecha hacia abajo" />
			</button>
		</section>
	)
}