import React, { Fragment } from 'react'
import { Header } from '../Header'
import { Helmet } from 'react-helmet' 
// import { Footer } from '../Footer'

export const Layout = ({ children }) => {
	return (
		<Fragment>
			<Helmet>
				<meta charset="UTF-8"/>
				<title>Panda Group</title>
				<meta name="description" content="Panda Group es un conglomerado de empresas dedicadas a promover y explorar el potencial de la tecnología blockchain y criptomonedas." />
				<meta name="robots" content="index, follow" />
				
				{/* social media */}

				<meta property="og:type" content="website" />
				<meta property="og:title" content="Panda Group" />
				<meta property="og:description" content="Panda Group es un conglomerado de empresas dedicadas a promover y explorar el potencial de la tecnología blockchain y criptomonedas." />
				{/* <meta property="og:image" content="LINK TO THE IMAGE FILE" /> */}
				<meta property="og:url" content="https://pandagroup.xyz" />
				

				<meta name="twitter:title" content="Panda Group" / >
				<meta name="twitter:description" content="Panda Group es un conglomerado de empresas dedicadas a promover y explorar el potencial de la tecnología blockchain y criptomonedas." />
				{/* <meta name="twitter:image" content="LINK TO IMAGE" /> */}
				<meta name="twitter:site" content="@PandGroup" />
				<meta name="twitter:creator" content="@PandGroup" />
			</Helmet>
			<Header />
			{
				children
			}
			{/* <Footer /> */}
		</Fragment>
	)
}

