import React from 'react'

import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import * as styles from './index.module.css' 

import logobanner1 from '../../../images/home/meet/logo_banner1.png'
import logobanner2 from '../../../images/home/meet/logo_banner2.png'
import logobanner3 from '../../../images/home/meet/logo_banner3.png'
import logobanner4 from '../../../images/home/meet/logo_banner4.png'
import logobanner5 from '../../../images/home/meet/logo_banner5.png'

import bannerImg1 from '../../../images/home/meet/banner1.png'
import bannerImg2 from '../../../images/home/meet/banner2.png'
import bannerImg3 from '../../../images/home/meet/banner3.png'
import bannerImg4 from '../../../images/home/meet/banner4.png'
import bannerImg5 from '../../../images/home/meet/banner5.png'

const bannerImages = [
	{
		logo: logobanner1,
		img: bannerImg1,
	},
	{
		logo: logobanner2,
		img: bannerImg2,
	},
	{
		logo: logobanner3,
		img: bannerImg3,
	},
	{
		logo: logobanner4,
		img: bannerImg4,
	},
	{
		logo: logobanner5,
		img: bannerImg5,
	}
]


const MeetItem  = ({ banner, img, altImg = '', title, paragraphs, alingRight = false }) => {
	return (
		<article
			className={`
			${styles.meetItem_article}
			${alingRight ? styles.meetItem_article_right : ''}
			`}
		>
			<div className={styles.meetItem_banner}>
				<img src={banner} alt='banner img' className={styles.meetItem_banner_img} />
				<img
					src={img}
					alt={altImg}
					className={`
					${styles.meetItem_banner_logo}
					${alingRight ? styles.meetItem_banner_logo_right : ''}
					`}
				/>
				<h2
					className={`
					${styles.meetItem_title}
					${ alingRight ? styles.meetItem_title_right : ''}
					`}
				>
					{title}
				</h2>	
			</div>
			<p 
				className={`
				${styles.meetItem_text}
				${ alingRight ? styles.meetItem_text_right : ''}
				`}
			>
				{paragraphs[0]}
			</p>
			<p 
				className={`
				${styles.meetItem_text}
				${ alingRight ? styles.meetItem_text_right : ''}
				`}
			>
				{paragraphs[1]}
			</p>
		</article>
	)
}

export const Meet = ({ texts }) => {
	const [isIntersecting, ref] = useIntersectionObserver() 

	return (
		<section
			ref={ref}
			className={`${styles.section} ${isIntersecting ? styles.sectionBackground : ''}`}
			id='meet'
		>
			<h1 className={styles.mainTitle}>
				{ texts && texts.title }
			</h1>
			<div className={styles.itemsContainer}>
				{
					(texts.meetItems && isIntersecting) && texts.meetItems.map((item, index) => {
						return (
							<MeetItem
								key={`MeetSection-item-${index}`}
								paragraphs={item.texts}
								title={item.title}
								img={bannerImages[index].logo}
								banner={bannerImages[index].img}
								alingRight={(index + 1)  % 2 === 0}
							/>	
						)
					})
				}
			</div>
		</section>
	)
}