import React from 'react'
import { BoldText } from '../../general/BoldText'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'

import * as styles from './index.module.css'

import twitterIcon from '../../../images/home/contact/twitter_icon.png'
import instagramIcon from '../../../images/home/contact/instagram_icon.png'
import facebookIcon from '../../../images/home/contact/facebook_icon.png'
import linkedinIcon from '../../../images/home/contact/linkedin_icon.png'


export const Contact = ({ texts }) => {
	const [isIntersecting, ref] = useIntersectionObserver()
	return (
		<section
			className={`${styles.section} ${isIntersecting ? styles.sectionBackground : ''}`}
			id='contact'
			ref={ref}
		>
			<h1 className={styles.title}>
				{texts && texts.title}
			</h1>
			<div className={styles.infoSection}>
				<BoldText text={texts.description}/>
				<div className={styles.infoSection_emailContainer}>
					<p className={styles.infoSection_emailText}>
						{texts && texts.email.callToAction}
					</p>
					<h2 className={styles.infoSection_email}>
						{texts && texts.email.mail}
					</h2>
				</div>
				<div className={styles.infoSection_socialMedia}>
					<BoldText text={texts.socialMediaTitle}/>
					<nav className={styles.infoSection_socialMedia_nav}>
						<a
							target='_blank'
							rel='noreferrer' 
							href='https://twitter.com/pandgroup'
							className={styles.infoSection_socialMedia_item}
						>
							<img
								src={twitterIcon}
								alt=''/>	
						</a>
						<a
							target='_blank'
							rel='noreferrer'
							href='https://www.instagram.com/pandgroup/'
							className={styles.infoSection_socialMedia_item}
						>
							<img
								src={instagramIcon}
								alt=''/>	
						</a>
						<a
							target='_blank'
							rel='noreferrer'
							href='https://www.facebook.com/pandagroupcompany/'
							className={styles.infoSection_socialMedia_item}
						>
							<img
								src={facebookIcon}
								alt=''/>	
						</a>
						<a
							target='_blank'
							rel='noreferrer'
							href='https://www.linkedin.com/company/panda-group-company/'
							className={styles.infoSection_socialMedia_item}
						>
							<img
								src={linkedinIcon}
								alt=''/>	
						</a>
					</nav>
				</div>
			</div>
		</section>
	)
}