import React, { useState, useEffect } from 'react'
import { getLastNews } from '../../../utils/getLastNews'
import { NewsCarrousel } from '../NewsCarrousel'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'

import * as styles from './index.module.css'

export const News = ({ texts }) => {
	const [isIntersecting, ref] = useIntersectionObserver()
	const [newsData, setNewsData] = useState(null)

	useEffect(() => {
		let mounted = true
		if (isIntersecting) {
			getLastNews()
				.then(data => {
					if (mounted) {
						setNewsData(data)
					}
				})
				.catch(error => {
					console.error(error.message)
				})
		}
		return () => {
			mounted = false
		}
	}, [isIntersecting])

	return (
		<section
			className={`${styles.section} ${isIntersecting ? styles.sectionBackground : ''}`}
			ref={ref}
		>
			<h1 className={styles.title}>
				{texts && texts.title}
			</h1>
			<p className={styles.description} >
				{texts && texts.description}
			</p>
			<br />
			<h4 className={styles.callToAction}>
				{texts && texts.callToAction}
			</h4>
			{
				newsData
					? <NewsCarrousel items={newsData} />
					: <div className={styles.loadingSpinner}></div>
			}
		</section>
	)
}